<template>
<b-dropdown :disabled="disabled" block class="dropdown-select">
  <template #button-content>
    <div class="d-flex justify-content-between align-items-center form-control form-control-solid">
      <div class="d-flex align-items-center" :class="[{'form-control is-invalid px-0': invalid}]"
           style="overflow: hidden; width: calc(100% - 20px)"
      >
        <div class="d-flex justify-content-between flex-nowrap">
          <div class="d-flex align-center" style="">
            <div v-for="option in selectedOptions" :key="callOptionValue(option)"
                 class="btn btn-secondary btn-sm py-1 mr-1"
            >
              <span class="svg-icon" @click.prevent.stop="select(option)">
                <!--suppress CheckTagEmptyBody, HtmlUnknownTag, HtmlUnknownTarget -->
                <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
              </span>
              {{ callOptionName(option) }}
            </div>
          </div>
        </div>
        <span v-if="choiceEmpty" v-once
              class="text-muted"
        >{{ placeholder || $t('PLACEHOLDER.SELECT') }}</span>
      </div>
      <div class="d-flex">
        <div v-if="!choiceEmpty"
             class="svg-icon svg-icon-secondary svg-icon-md d-flex align-center cursor-pointer mr-3"
             @click.prevent.stop="empty()"
        >
          <!--suppress CheckTagEmptyBody, HtmlUnknownTag, HtmlUnknownTarget -->
          <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
        </div>
        <span class="svg-icon svg-icon-md mr-0">
          <!--suppress CheckTagEmptyBody, HtmlUnknownTag, HtmlUnknownTarget -->
          <inline-svg src="/media/svg/icons/Navigation/Angle-down.svg" />
        </span>
      </div>
    </div>
  </template>
  <b-dropdown-header>
    <b-form-input v-model="search" :placeholder="$t('BASE.SEARCH')" class="form-control-solid" debounce="300" trim
                  type="text"
    />
  </b-dropdown-header>
  <options :empty="optsEmpty"
           :key-resolver="callOptionName"
           :loader="load"
           :loading="pager.loading"
           :options="opts"
           :selection="selectedOptions"
           :value-resolver="callOptionValue"
           @select="select"
  />
</b-dropdown>
</template>

<script>

import generic from './generic'

export default {
  name: 'Multi',
  mixins: [generic],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    }
  },
  computed: {
    choiceEmpty() {
      return this.selectedOptions.length === 0
    },
    selectedOptions() {
      return this.value
    },
  },
  methods: {
    selected(option) {
      let res = option
      this.selectedOptions.forEach((c) => {
        if (this.callOptionValue(c) === this.callOptionValue(option)) {
          res = c
        }
      })
      return res
    },
    select(option) {
      const opts = this.selectedOptions
      if (opts.map((so) => this.callOptionValue(so))
        .includes(this.callOptionValue(option))) {
        this.dispatch(opts.filter((so) => this.callOptionValue(so) !== this.callOptionValue(option)))
      } else {
        opts.push(option)
        this.dispatch(opts)
      }
    },
    empty() {
      this.dispatch([])
    },
    dispatch(val) {
      this.$emit('input', val)
    },
  },
}
</script>
